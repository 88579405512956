<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">经营分析</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp a-mlr-17" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <analysis-station :pageParam.sync="pageParam"></analysis-station>
        </el-card>
    </div>
</template>

<script>
import analysisStation from './child/analysis-station.vue'
    export default {
    components: { analysisStation },
        data () {
            return {
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.getAnalysisStationPage,
                    method: "post",
                    params: {
                        companyId: '',
                        incomeId: '',
                        stationId: '',
                        stationIdList: [],
                        provinceCode: '',
                        cityCode: '',
                        areaCode: '',
                        startDay: '',
                        endDay: ''
                    },
                    freshCtrl: 1,
                },
            }
        },
        created () {
            this.pageParam.params.startDay = this.$getDay.getTodayBeforeDays(30)
            this.pageParam.params.endDay = this.$getDay.getTodayBeforeDays(0)
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods:{
            exportfile () {
                this.$exfile({
                    code: 10,
                    fileName: this.pageParam.params.startDay + ' 至 ' + this.pageParam.params.endDay + ' 站点经营分析',
                    startTime: this.pageParam.params.startDay,
                    endTime: this.pageParam.params.endDay,
                    params: this.pageParam.params
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
/deep/ .s-search-label{
    width: 100px !important;
}
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.sjys{
    display: flex !important;
    justify-content: flex-start !important;
}
.companyNoComplete{
    max-width: 120px;
    color: #666;
}
</style>